import { atom, RecoilState, SetterOrUpdater } from 'recoil';

export const loadingStore: RecoilState<boolean> = atom<boolean>({
  key: 'loadingStore',
  default: false,
});

export const setIsLoading = (setRecoil: SetterOrUpdater<boolean>) => {
  setRecoil(true);
};

export const setIsNotLoading = (setRecoil: SetterOrUpdater<boolean>) => {
  setRecoil(false);
};
